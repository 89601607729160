import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';

import { feedComponent } from './feed/feed-component';

import { FeedService } from './services/feed-service.service'

import {MatCardModule} from '@angular/material/card';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSidenavModule} from '@angular/material/sidenav';
import { TechnicalComponent } from './tech/technical/technical.component';
import { AppRoutingModule } from './app-routing.module';
import { NewsComponent } from './news/news.component';


@NgModule({
  declarations: [
    AppComponent,
   feedComponent,
   TechnicalComponent,
   NewsComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    MatCardModule,
    MatToolbarModule,
    MatListModule,
    MatSidenavModule,
    AppRoutingModule
  ],
  providers: [
    FeedService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }