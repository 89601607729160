

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Feed} from '../models/feed';

import { Observable,from } from 'rxjs';

@Injectable()
export class FeedService {

  private url = 'https://api.rss2json.com/v1/api.json?rss_url=';
  constructor(private http: HttpClient){

  }
  getFeed(u: string): Observable<any> {
    var xml = this.http.get<any>(u);
    console.log(xml);
    return this.http.get<Feed>(this.url + u);

  }

}
