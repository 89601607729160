
 <section  class="portfolio">
<div class="container">
 

  <div class="section-title" data-aos="zoom-in">

    <!-- <h3>{{this.rssParsed?.feed.title}} </h3> -->
    <!-- <p>{{this.rssParsed?.feed.description}}</p> -->
  </div>
  <div class="row" data-aos="fade-up" data-aos-delay="100" >

    <div  class="col-lg-4 col-md-6"  *ngFor="let entry of rssParsed?.items">
      <img src="{{entry.enclosure.link}}" height="auto" width="100%"  class="img-fluid" alt="">
      <!-- <img src="{{entry.enclosure.thumbnail}}" height="auto" width="100%"  class="img-fluid" alt=""> -->
      <!-- <img src="{{entry.image}}" class="img-fluid" alt=""> -->


      <div>
        <h4><a target="_blank" href="{{entry.link}}" class="details-link" title="More Details">{{entry.title}}</a></h4>
      </div>
    </div>

  </div>

</div>
</section>
<!-- <ul>
  <li *ngFor="let entry of rssParsed?.items">
    <div class="row">
      <img width="200px" src={{entry.thumbnail}}/>
  </div>

  <div class="row">
    <a target="_blank" href={{entry.link}}>  {{entry.title.trim().substring(0,30)}} . . .</a>
  </div>




    </li>
</ul> -->
