import { Component, OnInit, Input, Output } from '@angular/core';

import { FeedService } from '../services/feed-service.service';

import {Feed} from '../models/feed'

@Component({
  selector: 'rss-feed',
  templateUrl: 'feed-component.html'
})
export class feedComponent implements OnInit {
  @Input() url: string;
  id: string;
  @Output() rssParsed: Feed;


  constructor(private rssParser: FeedService) { }

  ngOnInit() {
    this.rssParser.getFeed(this.url).subscribe(rssParsed1 => {
      this.rssParsed = rssParsed1;
    });

  }
}
