import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TechnicalComponent} from './tech/technical/technical.component'
import { NewsComponent} from './news/news.component';
const routes: Routes = [
  {path: 'tech' , component: TechnicalComponent},
  {path: 'news' , component: NewsComponent},
  { path: '',  redirectTo: '/news', pathMatch: 'full' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }